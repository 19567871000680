<template>
    <div class="dropdown">
        <button class="dropbtn"> <img src="/img/bandera-blanca.png" class="bandera">{{$t('web.idiomas.' + lStore.lang)}}
            <img src="/img/flecha-blanca.png" class="flecha">
        </button>
        <div class="dropdown-content">
            <a href="/es/home"> {{ $t('web.idiomas.es') }}</a>
            <a href="/en/home"> {{ $t('web.idiomas.en') }}</a>
        </div>
    </div>
</template>
<script>
import { localeStore } from '@/stores/locale';
export default {
    name: 'language-switcher',
    data() {
        const lStore = localeStore();
        return {
            lStore: lStore,
        }
    },
}

</script>
  
  
<style lang="scss" scoped>
.flecha {
    margin-left: 10px;
    padding-bottom: 2px;
}

.bandera {
    margin-right: 10px;
    margin-left:10px;
}

.dropbtn {
    background-color: rgba(0, 76, 69, 0.8);
    color: white;
    
    padding: 19.5px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    padding-left: 0px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: black;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: #1E2F3F;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: white;
    color: rgb(0, 76, 69);
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: rgb(0, 76, 69);
    color:white
}
</style>