<template>
  <app-header></app-header>
  <router-view/>
  <app-footer></app-footer>
</template>


<script>
  import header from '@/components/app-header';
  import footer from '@/components/app-footer';
  export default{
    name : 'app',
    setup(){},
    mounted(){},
    components : {
      'app-header': header,
      'app-footer': footer
    },
    methods : {}
  }
</script>

<style lang="scss">
  @import '@/assets/style.scss';
  @import '@/assets/responsive.scss';
  @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,700&display=swap');
</style>