import { createApp } from 'vue';
import { createPinia } from 'pinia';

import { i18n } from '@/locales/i18n'

import axios from 'axios'
import VueAxios from 'vue-axios'

import App from './App.vue';
import router from './router';
import './registerServiceWorker'; //For PWA

import './vee-validate'

axios.defaults.baseURL = process.env.VUE_APP_BACK;


const app = createApp(App)
    .use(createPinia())
    .use(i18n)
    .use(VueAxios, axios)
    .use(router);
    
app.mount('#app');
