<template>
                <img v-if="lStore.lang=='es'" src="/img/logo-dcoop-es.svg" alt="Dcoop" class="logo-home">
                <img v-if="lStore.lang=='en'" src="/img/logo-dcoop-en.svg" alt="Dcoop" class="logo-home">
          

</template>

<script>
import { localeStore } from '@/stores/locale';

export default {
    
    name: 'logo-home',
    components: {

    },
    data(){
    const lStore = localeStore();
        return {
            lStore: lStore,
        }   
  },

}
</script>
<style>
img.logo-home{
    width: 200px;
}
</style>