<template>
    <header class="app-header" :min="minmenu">
        <div class="top" @mouseleave="showejes = false">
            <router-link :to="{name:'home'}">
                <logo></logo>
            </router-link>
            <nav class="row-end" :showmenu="showmenumovil">

                <a @click="showmenumovil = !showmenumovil" class="close">✕</a>
                <router-link :to="{name:'nuestro-compromiso'}">{{$t('web.header.compromiso')}} {{$t('web.header.dcoop')}}
                </router-link>

                <a @click="showejes = true" :eje="$route.meta.eje" class="ejes">{{$t('web.header.ejes_accion')}}</a>
                <div class="ejes-accion" v-show="showejes">
                    <router-link :to="{name:'cuidamos-el-medioambiente'}">{{$t('web.header.cuidamos')}}
                        {{$t('web.header.of_enviroment')}}</router-link>
                    <router-link :to="{name:'cuidamos-a-las-personas'}">{{$t('web.header.cuidamos_dos')}}
                        {{$t('web.header.about_people')}}</router-link>
                    <router-link :to="{name:'cuidamos-el-medio-rural'}">{{$t('web.header.cuidamos_dos')}}
                        {{$t('web.header.rural_areas')}}</router-link>
                </div>

                <router-link :to="{name:'seguimos-trabajando'}">{{$t('web.header.seguimos_trabajando')}}</router-link>
                <router-link :to="{name:'contacto'}">{{$t('web.header.contacto')}}</router-link>
                <language></language>
            </nav>

            <button class="menu-mvl" @click="showmenumovil = !showmenumovil">
                <span></span><span></span><span></span>
            </button>

        </div>
    </header>
</template>

<script>
import language from '@/components/language';
import logo from '@/components/logo';

export default {
    name: 'app-header',
    components: {
        'language': language,
        'logo': logo,


    },
    data: () => ({
        showmenu: false,
        minmenu: false,
        showejes: false,
        showmenumovil: false,
   
    }),
    mounted() {
        this.checkmenu();
        window.addEventListener('scroll', () => {
            window.requestAnimationFrame(() => {
                this.checkmenu();
            });
        });
        
    },
    methods: {
        checkmenu() {
            var position = window.scrollY;
            if (position > 100) {
                this.minmenu = true;
            } else if (position <= 100 && this.minmenu) {
                this.minmenu = false;
            }
        }
    },
    watch: {
        '$route'() {
            this.showmenu = false;
            this.showmenumovil = false;
        }
       
    }
}
</script>
<style>
.flecha {
    margin-left: 10px;
    padding-bottom: 2px;
}

.bandera {
    margin-right: 10px;
}

.dropbtn {
    background-color: transparent;
    color: #1E2F3F;
    ;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    padding-left: 0px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: #1E2F3F;
    ;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #1E2F3F;
    ;
    color: white;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: white;
}
</style>