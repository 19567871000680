
export default {
    generateTextFilters(text, columns, textFilter = 1) {
        var filters = [];
        var column = null;
        if (text == null) {
            return filters;
        }
        for (column of columns) {
            filters.push({
                filterPack: true,
                filters: [{
                    name: column,
                    search: text,
                    textFilter: textFilter,
                }],
            })
        }
        return filters;
    },
    LoaderScrollToTop(position) {
        setTimeout(function () {
            let element = document.getElementById("app");
            element.scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        }, 200);
        return position
    },
    CutText(texto, limite) {
        var puntosSuspensivos = "@.";
        if (texto.length > limite) {
            texto = texto.substring(0, limite) + puntosSuspensivos;
        }

        return texto;
    },
    getLocale() {
        let lang = navigator.language || navigator.userLanguage;
        if (lang.includes("-")) {
            lang = lang.split("-")[0];
        }
        let messages = this.loadLocaleMessages();
        let langs = Object.keys(messages);
        if (langs.includes(lang)) {
            return lang;
        }
        return null;
    }
}