<template>
  <footer>
    <div class="top row-between">
      <div class="row-start">
        <img class="logo" src="@/assets/img/dcoop.svg" alt="" />
        <div class="footer-documentos">
          <div>
            <a
              class="link"
              target="_blank"
              :href="$t('web.footer.enlace_privacidad')"
              >{{ $t("web.footer.politica_privacidad") }}</a
            >
            <a
              class="link"
              target="_blank"
              :href="$t('web.footer.enlace_cookies')"
              >{{ $t("web.footer.politica_cookies") }}</a
            >
            <a
              class="link"
              target="_blank"
              :href="$t('web.footer.enlace_legal')"
              >{{ $t("web.footer.aviso_legal") }}</a
            >
          </div>
          <div class="descargas-siguenos">
            <div class="descargas">
              <a
                class="link -resumen-ejecutivo"
                target="_blank"
                :href="$t('web.footer.resumen_pdf')"
                download
              >
                <img
                  class="logo"
                  src="/img/icons/icon-descarga-white.svg"
                  alt=""
                />
                {{ $t("web.footer.resumen_ejecutivo") }}
              </a>
              <a
                class="link -resumen-ejecutivo"
                target="_blank"
                :href="$t('web.footer.estrategia_pdf')"
                download
              >
                <img
                  class="logo"
                  src="/img/icons/icon-descarga-white.svg"
                  alt=""
                />
                {{ $t("web.footer.estrategia_sostenibilidad") }}
              </a>
              <a
                class="link -resumen-ejecutivo"
                target="_blank"
                :href="$t('web.footer.politica_pdf')"
                download
              >
                <img
                  class="logo"
                  src="/img/icons/icon-descarga-white.svg"
                  alt=""
                />
                {{ $t("web.footer.politica_sostenibilidad") }}</a
              >
              <a
                class="link -resumen-ejecutivo"
                target="_blank"
                :href="$t('web.footer.plan_pdf')"
                download
              >
                <img
                  class="logo"
                  src="/img/icons/icon-descarga-white.svg"
                  alt=""
                />
                {{ $t("web.footer.plan_biodiversidad") }}</a
              >
            </div>
            <div>
              <nav class="siguenos row-start">
                <span>{{ $t("web.footer.siguenos") }}</span>
                <a href="https://www.facebook.com/grupoDcoop/" target="_blank">
                  <i class="fa-brands fa-facebook-f"></i>
                </a>

                <a href="https://twitter.com/grupodcoop" target="_blank">
                  <i class="fa-brands fa-x-twitter"></i>
                </a>
                <a href="https://www.instagram.com/grupodcoop/" target="_blank">
                  <i class="fa-brands fa-instagram"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCsdLzF24KOWkyV6vYzUYvxg"
                  target="_blank"
                >
                  <i class="fa-brands fa-youtube"></i>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom"></div>
  </footer>
</template>
