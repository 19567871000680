import { createRouter, createWebHistory } from "vue-router";
import { localeStore } from "@/stores/locale";
import { i18n, loadLocaleMessages, setI18nLanguage } from "@/locales/i18n";
import utils from "@/utils/utils.js";

const routes = [
  {
    path: "/:lang(en|es)?",
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "nuestro-compromiso",
        name: "nuestro-compromiso",
        component: () => import("@/views/nuestro-compromiso.vue"),
      },
      {
        path: "seguimos-trabajando",
        name: "seguimos-trabajando",
        component: () => import("@/views/seguimos-trabajando.vue"),
      },

      {
        path: "contacto",
        name: "contacto",
        component: () => import("@/views/contacto.vue"),
      },

      //Others
      {
        path: "politica-de-privacidad",
        name: "politica-de-privacidad",
        component: () => import("@/views/politica-de-privacidad.vue"),
      },

      //Axis
      {
        path: "cuidamos-el-medioambiente",
        name: "cuidamos-el-medioambiente",
        component: () => import("@/views/cuidamos-medioambiente.vue"),
      },
      {
        path: "cuidamos-a-las-personas",
        name: "cuidamos-a-las-personas",
        component: () => import("@/views/cuidamos-a-las-personas.vue"),
      },
      {
        path: "cuidamos-el-medio-rural",
        name: "cuidamos-el-medio-rural",
        component: () => import("@/views/cuidamos-el-medio-rural.vue"),
      },

      //Items
      {
        path: "noticias",
        name: "noticias",
        component: () => import("@/views/noticias.vue"),
      },
      {
        path: "noticias/:id",
        name: "noticia",
        props: true,
        component: () => import("@/views/noticia.vue"),
      },
      {
        path: "videos",
        name: "videos",
        component: () => import("@/views/videos.vue"),
      },
      {
        path: "documentos",
        name: "documentos",
        component: () => import("@/views/documentos.vue"),
      },
      {
        path: "enlaces",
        name: "enlaces",
        component: () => import("@/views/enlaces.vue"),
        meta: {
          goTop: true,
        },
      },
      {
        path: "lineas-de-trabajo/:id?",
        name: "lineas-de-trabajo",
        props: true,
        component: () => import("@/views/lineas-de-trabajo.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const store = localeStore();
  if (to.name == null || to.params.lang == null || to.params.lang.length < 2) {
    if (store.lang == null) {
      store.lang = utils.getLocale() ?? "es";
    }

    to.params.lang = store.lang;
    next({
      name: to.name ?? "home",
      params: to.params,
    });
    return;
  }

  if (to.params.lang) {
    store.lang = to.params.lang;
    setI18nLanguage(i18n, store.lang);
  }
  
  if (to.query.httpstatus) {
    let metaComponent = document.createElement("meta");
    metaComponent.name = "prerender-status-code";
    metaComponent.content = to.query.httpstatus;
    document.head.appendChild(metaComponent);
    if (to.query.httpstatus == 301) {
      metaComponent = document.createElement("meta");
      metaComponent.name = "prerender-header";
      metaComponent.content = "Location: " + location.href;
      document.head.appendChild(metaComponent);
    }
    delete to.query.httpstatus;
  }
  next();
});

export default router;
